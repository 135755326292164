#SettingsCard 
{
    color: black;
    max-width: 350px;

    .card-title 
    {
        font-size: 2rem;
        font-weight: 600;
    }

    .active-card
    {
        &:hover
        {   
            transform: scale(1.1);
            cursor: pointer;
        }
    }

    .grey-out
    {
        opacity: 0.2;
    }    
}

