
:root {
    --background-color: 0E0B16;
}
$backgroundColor: var(--background-color);


.App {
    text-align: center;
    background-color: #0E0B16;

    h1
    {
        color: #17a2b8;
        font-family: Arial, Helvetica, sans-serif;
    }

    .modal 
    {
        .label {
            color: black;
        }

        
        .table
        {
            color: black !important;
        }
    }

    .table
    {
        color: white !important;

        td {
            border: none !important;
        }

        th 
        {
            border: none !important;
        }

        border-top: white 1px solid;

    }


    .label
    {
        width: 200px;
    }


    .container
    {
    color: white;
    }

    body {

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    .navbar-brand
    {
        color: #17a2b8 !important;
    }

    .navbar-toggler{
    border-color:#17a2b8 !important;
    }

    .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(23,162,184)' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }
}


.App-2 {
    text-align: center;
    background-color: #0E0B16;

    h1
    {
        color: hotpink;
        font-family: Arial, Helvetica, sans-serif;
    }

    .modal 
    {
        .label {
            color: black;
        }

        
        .table
        {
            color: black !important;
        }
    }

    .table
    {
        color: white !important;

        td {
            border: none !important;
        }

        th 
        {
            border: none !important;
        }

        border-top: white 1px solid;

    }

    .label
    {
        width: 200px;
    }

    .container
    {
    color: white;
    }

    body {

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }



    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    .navbar-brand
    {
        color: hotpink !important;
    }

    .navbar-toggler{
    border-color: hotpink !important;
    }

    .btn-outline-info{
        color: hotpink;
        border: 1px solid hotpink;

        &:hover
        {
            background-color: hotpink;
            border-color: hotpink;
        }
    }

    .btn-info{
        background-color: hotpink;
        border: 1px solid hotpink;

        &:hover
        {
            background-color: hotpink;
            border-color: hotpink;
        }
    }

    .btn-outline-success{
        color: hotpink;
        border: 1px solid hotpink;
    }

    .btn-info{
        background-color: hotpink;
    }

    .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,105,180)' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }
}
  
  
