.dashboard-card {

    border: 1.5px #007BFF solid;
    border-radius: 5px;    

}


h2 {
    color: #007BFF !important;
}